import React from 'react'
import styled from 'styled-components'
import { TableRow, TableCell } from '@material-ui/core'
import { ContractorOrderedMeasurement, OrderedMeasurementProperty, PropertyStatus } from '../../../apollo/generated';

const propertyToAddress = (p: OrderedMeasurementProperty) => {
	return `${p.houseNumber} ${p.streetName}\n${p.city}, ${p.state} ${p.zipCode}`
}

const extractStatus = (o: ContractorOrderedMeasurement) => {
	if (o.property.ready === PropertyStatus.PaymentFailed) {
		return 'Payment Failed'
	} else if (o.property.ready === PropertyStatus.Canceled || o.property.ready === PropertyStatus.Denied) {
		return 'Unavailable'
	}	else if (o.property.ready !== PropertyStatus.Ready) {
		return 'Processing'
	} else if (!o.invitedEmails || o.invitedEmails.length === 0) {
		return 'Completed'
	}
	return 'Completed and sent to client'
}

const CustomBidRowItem = ({ orderedMeasurement, onClick }: { orderedMeasurement: ContractorOrderedMeasurement; onClick?: () => void }) => (
	<StyledRow key={orderedMeasurement.id} onClick={onClick}>
		<TableCell style={{ whiteSpace: 'pre' }}>{propertyToAddress(orderedMeasurement.property)}</TableCell>
		<TableCell>{new Date(orderedMeasurement.createdAt).toLocaleDateString()}</TableCell>
		<TableCell>{extractStatus(orderedMeasurement)}</TableCell>
	</StyledRow>
)

export default React.memo(CustomBidRowItem)

const StyledRow = styled(TableRow)`
	cursor: pointer;
	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
`

import React from 'react'
import ContractorSidebar from '../../components/contractorSidebar'
import CustomBids from '../../components/contractor/CustomBids'
import SEO from '../../components/seo/seo'
import DisastrousErrorHandler from '../../components/ui/DisastrousErrorHandler';

const NewJob = () => (
	<DisastrousErrorHandler homeLink="/contractor/jobs">
		<ContractorSidebar>
			<SEO title="Ordered Specs" keywords={['3d', 'construction']} />
			<CustomBids />
		</ContractorSidebar>
	</DisastrousErrorHandler>
)

export default NewJob;

import React from 'react'
import styled from 'styled-components'
import { TextField, Button, Typography, Tooltip } from '@material-ui/core';
import { useSendOrderedMeasurementToClientMutation } from '../../../apollo/generated'

const SendForm = ({ id, locked }: { id: string, locked?: boolean }) => {
  const [sendToClientMut] = useSendOrderedMeasurementToClientMutation()
  const [clientInvEmail, setClientInvEmail] = React.useState('')
  const [isLoading, setLoading] = React.useState(false)
  const [outcomeHelper, setOutcomeHelper] = React.useState({ color: 'red', text: '' })

  const _inviteClient = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()
      if (!clientInvEmail || isLoading || locked) {
        return
      }
      setLoading(true)
      const { data: respData, errors } = await sendToClientMut({ variables: { email: clientInvEmail, id } })
      setLoading(false)
      if (errors) {
        return setOutcomeHelper({ color: 'red', text: errors.map(e => e.message).join(', ') })
      } else if (!respData || !respData.sendOrderedMeasurementToClient || !respData.sendOrderedMeasurementToClient.success) {
        return setOutcomeHelper({ color: 'red', text: 'We had some trouble completing this request. Please contact us.' })
      }
      setOutcomeHelper({ color: 'green', text: 'Success! Email sent to ' + clientInvEmail })
      setClientInvEmail('')
    } catch (e) {
      console.warn(e)
      setOutcomeHelper({ color: 'red', text: `Error: ${e.message}` })
    }
    setLoading(false)
  }

  return (
    <Tooltip disableFocusListener={!locked} disableHoverListener={!locked} placement="top" disableTouchListener title="This feature will unlock when the property has been processed">
      <>
        <StyledForm locked={locked} onSubmit={_inviteClient}>
          <EmailField type="email" value={clientInvEmail} onChange={e => setClientInvEmail(e.target.value)} />
          <SendButton disabled={isLoading}>Send</SendButton>
        </StyledForm>
        {outcomeHelper.text && <HelperText outcomeColor={outcomeHelper.color}>{outcomeHelper.text}</HelperText>}
      </>
    </Tooltip>
  )
}

export default SendForm

const StyledForm = styled.form<{ locked?: boolean }>`
  padding-top: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${({ locked }) => !locked ? '' : '> * {opacity: .3; pointer-events: none;}'}
`

const EmailField = styled(TextField).attrs({
  margin: 'dense',
  variant: 'outlined',
  label: 'Client Email',
  required: true,
})`
  font-size: 12px;
  flex: 1;
  max-width: 300px;
  min-width: 180px;
`


const SendButton = styled(Button).attrs({
  type: 'submit',
  variant: 'contained'
})`
	height: 50px;
	margin-left: -4px;
	margin-top: 4px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
`

const HelperText = styled(Typography) <{ outcomeColor?: string }>`
  color: ${({ outcomeColor }) => outcomeColor || 'inherit'};
  margin: 8px;
`
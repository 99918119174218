import React from 'react'
import { Typography, TextField, Button } from '@material-ui/core'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'

import { getExampleRenoworksId, getExampleLegacyHomeSpecId , getExamplePropertySpec} from '../../activeJobs/NoPropertiesYet';
import {getExampleRenoworksDisplayId} from '../../homeowner/HomeInspect'
import { ContractorOrderedMeasurement, PropertyStatus } from '../../../apollo/generated'
import { formatDate } from '../../../utils/formatting'
import { GET_OPTIONS } from '../../jobCreator/jobCreator';
import MeasurementsDisplayer from '../../measurementsDisplayer';
import CanvasHouseViewer from '../../canvasHouseViewer/canvasHouseViewer';
import SendForm from './SendForm';
import Loading from '../../ui/Loading';
import { PropertyMeasurements } from '../../../apollo/types';

interface Props {
	orderedMeasurement?: ContractorOrderedMeasurement
}

const exampleSpec = getExamplePropertySpec()

const OrderedMeasurementModal = ({ orderedMeasurement }: Props) => {
	const { data, error, loading } = useQuery(GET_OPTIONS);
	if (!orderedMeasurement) {
		return null
	}
	if (loading) {
		return <Loading />
	}
	if (!data || loading) {
		return null
	}
	const selectedPropertySpecs: PropertyMeasurements = (data.selectedPropertySpecs && JSON.parse(data.selectedPropertySpecs)) || { selections: [] };

	const { renoworksId, legacyHomeSpecId, houseNumber, streetName, city, state, zipCode, propertySpec, ready } = orderedMeasurement.property
	const sampleMode = ready !== PropertyStatus.Ready
	const getStatusMessage = () => {
		if (ready === PropertyStatus.PaymentFailed) {
			return 'Your payment method on file failed. Please contact support. In the meantime, feel free to use this sample property to familiarize yourself with the system.';
		} else if (ready === PropertyStatus.Denied || ready === PropertyStatus.Canceled) {
			return 'No data is available for this property. Please contact support for details. Feel free to use this sample property to familiarize yourself with the system.'
		}
		return 'This property is still processing. In the meantime, feel free to use this sample property to familiarize yourself with the system.';
	}
	const canvasProps = sampleMode ? {
		legacyHomeSpecId: getExampleLegacyHomeSpecId(),
		renoworksId: getExampleRenoworksId(),
		propertySpec: exampleSpec,
		newViewer: false,
	} : {
		legacyHomeSpecId,
		renoworksId,
		propertySpec,
		newViewer: orderedMeasurement.property.newViewer,
	}

	return (
		<Outer>
			<ButtonRow>
				<div>
					<JobTitle>{houseNumber} {streetName}</JobTitle>
					<JobDescr>{city}, {state} {zipCode}</JobDescr>
				</div>
				<JobDates>{`Created: ${formatDate(orderedMeasurement.createdAt)}`}</JobDates>
			</ButtonRow>
			{sampleMode && <SampleMessaging align="center">{getStatusMessage()}</SampleMessaging>}
			<Inner>
				<CanvasHouseViewer
					style={{ minWidth: 300, height: 300, alignSelf: 'center', padding: 0, overflowY: 'hidden' }}
					{...canvasProps}
				>
					{sampleMode && <SampleStamp>SAMPLE</SampleStamp>}
				</CanvasHouseViewer>
				<MeasCont>{
					(selectedPropertySpecs.selections || []).length === 0 ?
						<HelperText style={{ alignSelf: 'center', textAlign: 'center' }}>Select home elements to view their measurements</HelperText> :
						<MeasurementsDisplayer compact propertyMeasurements={selectedPropertySpecs} />
				}</MeasCont>
			</Inner>
			{orderedMeasurement.property && (
				<Bottom>
					<HelperText>Share With Your Customer</HelperText>
					<Typography variant="subtitle2">Share the 3D product visualizer with your client&nbsp;&nbsp;
						<a target="_blank" rel="noopener" href={`/measurements?id=${sampleMode ? getExampleRenoworksDisplayId() : orderedMeasurement.property.renoworksDisplayId!}`} title="Preview 3d Visualizer">View Preview</a>
					</Typography>
					<SendForm locked={sampleMode} id={orderedMeasurement.id!} />
				</Bottom>
			)}
		</Outer>
	)
}

const Bottom = styled.div`
	display: flex;
	flex-direction: column;
`

const SampleMessaging = styled(Typography)`
	&& {
		border: 3px solid rgb(64,166,0);
    box-shadow: 0 2px 4px rgba(0,0,0,.3);
    padding: 7px 15px;
    margin: 12px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 18px;
	}
`

const SampleStamp = styled.div`
	position: absolute;
	top: 16px;
	right: 6px;
	transform: rotate(20deg);
	color: red;
	padding: 0px 4px;
	border-radius: 6px;
	border: 2px solid red;
	color: red;
	font-weight: bold;
	box-shadow: 0 2px 4px rgba(0,0,0,.3);
	font-size: 16px;
	background-color: rgba(255,255,255,.5);
	pointer-events: none;
`

const Inner = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: stretch;
	width: 100%;
	margin-bottom: 8px;

	> * {
		max-height: 324px;
		min-height: 200px;
		overflow-y: auto;
		overflow-x: hidden;
		position: relative;
		min-width: 200px;
		flex: 1;
		border: 1px solid rgba(0,0,0,.3);
		border-radius: 4px;
		margin: 8px;
		padding: 16px;
	}
`

const MeasCont = styled.div`
	&& {
		flex-direction: column;
		align-items: stretch;
		justify-content: center;
	}
`

const HelperText = styled(Typography).attrs({ variant: 'subtitle1' })`
	&& {
		font-weight: bold;
	}
`

const JobTitle = styled(Typography)`
	&& {
		font-size: 16px;
		letter-spacing: 0.15px;
		font-weight: 500;
	}
`

const JobDescr = styled(Typography)`
	&& {
		font-size: 14px;
		opacity: 0.85;
		text-overflow: ellipsis;
	}
`

const JobDates = styled(Typography)`
	&& {
		font-size: 13px;
		opacity: 0.75;
		text-overflow: ellipsis;
	}
`

const Outer = styled.div`
	display: flex;
	flex-direction: column;
	max-height: 95%;
`

const ButtonRow = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 0 12px;

	@media (min-width: 850px) {
		> *:last-child {
			margin-left: 8px;
		}

		> *:first-child {
			margin-right: auto;
		}
	}

	@media (max-width: 849px) {
		flex-direction: column;
		align-items: stretch;
		margin-top: 12px;
		margin-bottom: 12px;
		> * {
			margin: 4px;
		}
	}
`

export default React.memo(OrderedMeasurementModal)

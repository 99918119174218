import React from 'react'
import styled from 'styled-components'
import { Table, Toolbar, TableHead, TableRow, TableCell, TableBody, Typography, Button } from '@material-ui/core'
import { useContractorOrderedMeasurementsQuery, ContractorOrderedMeasurement } from '../../../apollo/generated'

import { TransparentCard } from '../../ui/TransparentCard'
import CustomBidRowItem from './CustomBidRowItem'

import ResponsiveModal from '../../ui/ResponsiveModal'
import OrderedMeasurementModal from '../../modals/OrderedMeasurementModal'
import NewHomeModal from '../../modals/newHomeModal'
import Loading from '../../ui/Loading';
import Snackbar from "@material-ui/core/Snackbar"
import { getHashValueAndRemove } from "../../../utils/helpers"

const helperText = "For you roofers out there! Here you can order complete property " +
	"data packages for your measurement needs for any client or prospect. An interactive, " +
	"spec-loaded 3D model of the property is generated and delivered to you. Further, you can " +
	"impress your client by sending them a link to the Design 3D Model of their own home. This will " +
	"assist them in visualizing real products applied to their home, and choosing a material and " +
	"color so you don't waste anymore time taking samples."

const CustomBids = () => {
	const [inspectMeasurement, setInspectMeasurement] = React.useState<null | ContractorOrderedMeasurement>(null)
	const [addingHome, setAddingHome] = React.useState(false)
	const [jobNotFound, setJobNotFound] = React.useState(false);

	const { loading, data, error, refetch } = useContractorOrderedMeasurementsQuery()
	if (loading) {
		return <Outer><Loading /></Outer>;
	}
	if (error) {
		return <Outer><div>Error: {error.message}</div></Outer>
	}

	const orderedMeasurements = data.contractorOrderedMeasurements

	const urlJobId = getHashValueAndRemove(!loading && !error, "measurement_id")
	if ((!loading && !error) && urlJobId) {
		if (orderedMeasurements.map(j => j.id).includes(urlJobId)) {
			setInspectMeasurement(orderedMeasurements.find(o => o.id === urlJobId))
		} else {
			setJobNotFound(true)
		}
	}

	return (
		<Outer>
			<StyledCard>
				<Inner>
					<Topbar>
						<Typography variant="h6" align="center">Ordered Specs</Typography>
						<Button style={{ position: 'absolute', right: 24 }} onClick={() => setAddingHome(true)}>
							New Order
						</Button>
					</Topbar>
					<Typography style={{ margin: 24, marginTop: 0, fontSize: 14 }} variant="subtitle2">{helperText}</Typography>
					<StyledTable>
						<TableHead>
							<TableRow>
								<TableCell>Address</TableCell>
								<TableCell>Created Date</TableCell>
								<TableCell>Status</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{
								orderedMeasurements.length > 0 ? (
									orderedMeasurements.map(row => (
										<CustomBidRowItem key={row.id} orderedMeasurement={row} onClick={() => setInspectMeasurement(row)} />
									))
								) : (
										<Typography variant="h5">No Ordered Specs to Display</Typography>
									)
							}
						</TableBody>
					</StyledTable>
				</Inner>
			</StyledCard>
			<ResponsiveModal open={!!inspectMeasurement} onClose={() => setInspectMeasurement(null)}>
				{inspectMeasurement && <OrderedMeasurementModal orderedMeasurement={inspectMeasurement} />}
			</ResponsiveModal>
			<ResponsiveModal open={addingHome} onClose={() => setAddingHome(false)} overflow={'inherit'}>
				<NewHomeModal onClose={(success) => {
					setAddingHome(false)
					success && refetch()
				}} />
			</ResponsiveModal>
			<ErrorSnackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={jobNotFound}
				autoHideDuration={5000}
				onClose={() => setJobNotFound(false)}
				ContentProps={{
					'aria-describedby': 'message-id',
				}}
				message={<span id="message-id">Could not find the job on your open jobs.</span>}
			/>
		</Outer>
	)
}

export default CustomBids

const StyledCard = styled(TransparentCard)`
	&& {
		margin: 24px;
		padding: 24px;
		@media (max-width: 748px) {
			margin: 16px 0;
			padding: 0;
		}
	}
`

const Topbar = styled(Toolbar)`
	display: flex;
	align-items: center;
	justify-content: center;
`

const Outer = styled.div`
	@media (max-width: 850px) {
		margin-top: 52px;
	}
	height: 100%;
	overflow-y: auto;
`

const Inner = styled.div`
	overflow-x: auto;
`

const StyledTable = styled(Table)`
	min-width: 400px;
	* {
		border-color: rgba(255, 255, 255, 0.3);
	}
`

const ErrorSnackbar = styled(Snackbar)`
	& > div {
		background-color: darkred;
	}
`;
